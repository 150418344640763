const apiUrl = import.meta.env.VITE_MK_API_URL; // apiUrl 不被混淆
const assetsUrl = import.meta.env.VITE_MK_ASSETS_URL; // apiUrl 不被混淆
const aesKey = import.meta.env.VITE_AES_KEY; // apiUrl 不被混淆
const aesfeKey = import.meta.env.VITE_AES_FEKEY; // apiUrl 不被混淆

function getApiUrl() {
  return apiUrl;
}

function getAesKey() {
  return aesfeKey;
}
function getAssetsUrl() {
  return assetsUrl;
}

export { getApiUrl, getAesKey, getAssetsUrl };
