import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { getAssetsUrl } from "../mkenv";

function set_iconStyle(iconname, aX, aY, scale, opacity) {
  //const icon_src = "../../images/icon/" + iconname + ".png";
  const icon_src = getAssetsUrl() + "/images/icon/" + iconname + ".png";

  let aX_units = "";
  let aY_units = "";
  if (iconname.includes("marker") == true) {
    aX_units = "fraction";
    aY_units = "pixels";
  } else {
    aX_units = "fraction";
    aY_units = "fraction";
  }

  const icon_style = new Icon({
    anchor: [aX, aY],
    anchorXUnits: aX_units,
    anchorYUnits: aY_units,
    scale: scale,
    src: icon_src,
    opacity: opacity,
  });

  return icon_style;
}
function set_textStyle(text, oX, oY) {
  /*https://stackoverflow.com/questions/14484787/wrap-text-in-javascript*/
  function stringDivider(str, width, spaceReplacer) {
    if (str.length > width) {
      let p = width;
      while (p > 0 && str[p] != " " && str[p] != "-") {
        p--;
      }
      if (p > 0) {
        let left;
        if (str.substring(p, p + 1) == "-") {
          left = str.substring(0, p + 1);
        } else {
          left = str.substring(0, p);
        }
        const right = str.substring(p + 1);
        return (
          left + spaceReplacer + stringDivider(right, width, spaceReplacer)
        );
      }
    }
    return str;
  }

  const text_style = new Text({
    text: stringDivider(text, 16, "\n"),
    font: "12px Microsoft YaHei",
    fill: new Fill({ color: "#1967D2" }),
    stroke: new Stroke({ color: "#ffffff", width: 1 }),
    textAlign: "right",
    textBaseline: "bottom",
    offsetX: oX,
    offsetY: oY, //-2.5,
    placement: "point",
    overflow: true,
  });

  return text_style;
}
function set_label(label_texts) {
  //console.log(label_texts);
  let label = "";
  for (let i = 0; i < label_texts.length; i++) {
    if (i != label_texts.length - 1) {
      if (label_texts[i] !== null) {
        label += label_texts[i] + "\n";
      }
    } else {
      if (label_texts[i] !== null) {
        label += label_texts[i];
      }
    }
  }
  //console.log(label);
  return label;
}

function getMaxPoly(polys) {
  var polyObj = [];
  //now need to find which one is the greater and so label only this
  for (var b = 0; b < polys.length; b++) {
    polyObj.push({ poly: polys[b], area: polys[b].getArea() });
  }
  polyObj.sort(function (a, b) {
    return a.area - b.area;
  });

  return polyObj[polyObj.length - 1].poly;
}
var polygon_style = new Style({
  geometry: function (feature) {
    //console.log(feature.getGeometry().getType());
    var retPoint;
    if (feature.getGeometry().getType() === "MultiPolygon") {
      retPoint = getMaxPoly(
        feature.getGeometry().getPolygons()
      ).getInteriorPoint();
    } else if (feature.getGeometry().getType() === "Polygon") {
      retPoint = feature.getGeometry().getInteriorPoint();
    }
    //console.log(retPoint)
    return retPoint;
  },
});

export { set_iconStyle, set_textStyle, set_label, polygon_style };
