import { ifCluster } from "../utils";

let feature_selected = null;
function features_by_hover(evt, map, overlay) {
  //such as panning or zooming.
  //console.log(evt.map.getView().getInteracting());
  //如果地图正在拖拽或者播放动画效果时，则不处理hover事件。
  //evt.map.getView().getAnimating();检测地图是否在缩放或者旋转或者播放动画效果中【例如flyto】。

  if (
    evt.dragging ||
    !map.pointermove ||
    evt.map.getView().getAnimating() ||
    evt.map.getView().getInteracting()
  ) {
    return;
  }

  if (feature_selected !== null) {
    //selected.setStyle(undefined);
    feature_selected = null;
  }

  if (map) {
    //const pixel = evt.pixel;
    const pixel = map.getEventPixel(evt.originalEvent);
    map.forEachFeatureAtPixel(pixel, function (feature) {
      feature_selected = feature;
      //selectStyle.getFill().setColor(f.get("COLOR") || "#eeeeee");
      //feature.setStyle(selectStyle);
      return true;
    });
  }
  if (feature_selected) {
    let getfeatureKeys = [];
    let featureinfo_keys = [];
    let featureinfo = [];
    let display_featureinfo = "";
    let feature_container = document.getElementById("feature_container");

    //获取feature的属性表{geometry: Wn, extmsg: 'H', iteminfo: {…}}
    let feature_obj = feature_selected.getProperties();
    //查找显示的marker 不处理，直接返回
    if (feature_obj["extmsg"]) return;
    //用户自定义的marker 不处理，直接返回，或者特殊处理
    if (feature_obj["mk_client"]) {
      //console.log(feature_selected.getKeys());
      if (feature_selected.get("name")) {
        display_featureinfo +=
          "<a style='font-size: 15px; font-weight: bold;'>" +
          feature_selected.get("name") +
          "</a><br>";
      }
      if (feature_selected.get("type")) {
        display_featureinfo +=
          "<a style='color:#808080;'>" +
          feature_selected.get("type") +
          "</a><br>";
      }
      //console.log(display_featureinfo);
      if (display_featureinfo == "") return;
      overlay.setPosition(evt.coordinate);
      //console.log(evt.coordinate);
      let feature_content = document.getElementById("feature_content");
      feature_content.innerHTML = display_featureinfo;
      feature_container.style.display = "block";
      document.getElementById("map").style.cursor = "pointer";
      return;
    }

    let feat_id = null;
    //聚合点
    feature_selected = ifCluster(feature_selected);
    if (feature_selected.getId())
      feat_id = feature_selected.getId().split(".")[0];

    //地铁出口信息，不处理，直接返回
    if (feat_id == "hk_mtr_exits") {
      //hover_container.style.display = "none";
      return;
    }

    //console.log(feat_id);
    //console.log(feature_selected.getKeys());
    //遍历feature的属性表
    for (var i = 0; i < feature_selected.getKeys().length; i++) {
      let getfeatureProperties = feature_selected.getKeys();
      getfeatureKeys.push(getfeatureProperties[i]);

      if (
        feature_selected.get(getfeatureProperties[i]) == null ||
        getfeatureProperties[i] == "geom" ||
        getfeatureProperties[i] == "id"
      ) {
        // console.log(feature_selected.get(getfeatureProperties[i]), " is null");
      } else {
        featureinfo_keys.push(getfeatureProperties[i]);
        featureinfo.push(feature_selected.get(getfeatureProperties[i]));

        //console.log(getfeatureProperties[i].includes("address"));

        if (getfeatureProperties[i].includes("name") == true) {
          display_featureinfo +=
            "<a style='font-size: 15px; font-weight: bold;'>" +
            feature_selected.get(getfeatureProperties[i]) +
            "</a><br>";
        } else if (getfeatureProperties[i].includes("cat") == true) {
          display_featureinfo +=
            "<a style='color:#808080;'>" +
            feature_selected.get(getfeatureProperties[i]) +
            "</a><br>";
        }
      }
    }
    overlay.setPosition(evt.coordinate);
    let feature_content = document.getElementById("feature_content");
    feature_content.innerHTML = display_featureinfo;
    feature_container.style.display = "block";
    document.getElementById("map").style.cursor = "pointer";
    //hover_container.style.display = "block";
    //console.log(evt.coordinate);
  } else {
    overlay.setPosition(undefined);
    feature_container.style.display = "none";
    document.getElementById("map").style.cursor = "default";
  }
}

export { features_by_hover };
