import { get } from "ol/proj";
import { getApiUrl, getAssetsUrl } from "./mkenv";
/*
const mk_geoserver_url = "http://103.246.160.202:40044/geoserver/";
const MapKing = {
  mk_version: "mk-api-v1.0.0",
  mk_geoserver_url: "http://103.246.160.202:40044/geoserver/",
  mk_bounds: [
    //99.63977813720703, 0.855000972747803, 119.26972961425781, 7.380556106567383,
    100.00127471125728,
    1.1513441389776788, 105.41664665589462, 3.5323655838048236,
  ],
  mk_extent: [
    //99.63977813720703, 0.855000972747803, 119.26972961425781, 7.380556106567383,
    100.00127471125728,
    1.1513441389776788, 105.41664665589462, 3.5323655838048236,
  ],
  mk_proj: get("EPSG:4326"),
};
*/
const MapKing = {
  mk_version: "mk-api-v1.0.0",
  mk_api_url: getApiUrl(),
  mk_assets_url: getAssetsUrl() + "/assets",
  //mk_api_url: "http://mkapi7yan.com",
  //hk+大湾区
  mk_gba: {
    mk_geoserver_url: "http://103.246.160.202:40044/geoserver",
    mk_view: {
      //center: [22.352696, 114.159864], //22.913259265,113.3856811523438
      center: [22.913259265, 113.3856811523438],
      extent: [
        111.3576431274414, 21.423738479614258, 115.4137191772461,
        24.402708053588867,
      ],
      projection: get("EPSG:4326"),
      zoom: 1,
      minZoom: 1,
      maxZoom: 42.5,
      zoomFactor: 1.4,
    },
    mk_basemap: "gba_basemap",
  },
  //hk
  mk_hk: {
    mk_geoserver_url: "http://103.246.160.202:40044/geoserver",
    mk_view: {
      center: [114.159864, 22.352696],
      //extent: [113.76, 22.13, 114.51, 22.58],
      projection: get("EPSG:4326"),
      zoom: 24,
      minZoom: 24,
      maxZoom: 39,
      zoomFactor: 1.4,
    },
    mk_basemap: "mk_webmap:hk_basemap",
  },
  //sg+my
  mk_sgmy: {
    mk_geoserver_url: "http://103.246.160.202:40044/geoserver",
    mk_view: {
      center: [3.146862099135947, 101.69601873353119],
      extent: [
        100.00127471125728, 1.1513441389776788, 105.41664665589462,
        3.5323655838048236,
      ],
      projection: get("EPSG:4326"),
      zoom: 1,
      minZoom: 5,
      maxZoom: 39,
      zoomFactor: 1.4,
    },
    mk_basemap: "my_webmap:my_basemap",
  },
  mk_res: {
    mk_geoserver_url: "http://103.246.160.202:40044/geoserver",
    mk_view: {
      center: [22.352696, 114.159864],
      //center: [22.913259265, 113.3856811523438],
      extent: [
        111.3576431274414, 21.423738479614258, 115.4137191772461,
        24.402708053588867,
      ],
      zoom: 4,
      projection: get("EPSG:4326"),
      //在 reslayer.js 计算得到的分辨率
      resolutions: [
        0.01584404706954956, 0.00792202353477478, 0.00396101176738739,
        0.001980505883693695, 0.0009902529418468475, 0.0004951264709234238,
        0.0002475632354617119, 0.00012378161773085594, 0.00006189080886542797,
        0.000030945404432713985, 0.000015472702216356993,
        0.000007736351108178496, 0.000003868175554089248,
        0.000001934087777044624, 9.67043888522312e-7,
      ],
      /*resolutions: [
        1.40625, 0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125,
        0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125,
        0.001373291015625, 0.0006866455078125, 0.00034332275390625,
        0.000171661376953125, 0.0000858306884765625,
      ],*/
    },
    mk_basemap: "gba_basemap",
  },
};
export { MapKing };
