import "./css/ol.css"; // 引入 OpenLayers 样式
import "./css/403.css"; // 引入 403 样式
import "./css/mapking.css";
import { Map, View } from "ol";

import Overlay from "ol/Overlay.js";
import OSM from "ol/source/OSM.js";
import TileLayer from "ol/layer/Tile.js";
import VectorSource from "ol/source/Vector";

//MapKing的配置文件，需要引入
import { MapKing } from "./config";
import { mousePositionControl, zoomControl, zoomSlider } from "./control";

import MK_BaseMap from "./layers/baselayer";
import HK_GOV_MAP from "./layers/hkgovlayer";
//import OSM_MAP from "./layers/osmlayer";
//import RES_MAP from "./layers/reslayer";
import MK_VectorLayer from "./layers/vectorlayer";
import { deepMergeOptions } from "./utils";

import {
  hk_mtr_stations_config,
  hk_mtr_exits_config,
  hk_business_listing_config,
  hk_shopping_malls_config,
  hk_district_councils_config,
  hk_common_districts_config,
  //gba
  gba_city_poi_config,
} from "./layers/vectorconfig";

import { features_by_hover } from "./control/features_by_hover";
import { features_by_click } from "./control/features_by_click";

import { addFeatures, getVlayerById, addLineFeatures } from "./maputils";
import { easeOut } from "ol/easing";

import { throttle } from "lodash-es"; // 确保安装了 lodash-es

class MK_MAP {
  constructor(option = {}) {
    this.option = deepMergeOptions(option, {
      targetid: "map",
      bgmap: "mk_hk",
      pointermove: true,
      pointerclick: true,
    });
    this.layers = [];
    this.map = null;
    this.initzoom = null;
  }
  initMap() {
    /*const targetid = this.option.targetid || "map";
    const mk_basemap = new MK_BaseMap({ bgmap: "mk_hk" });
    const map = new Map({
      target: targetid,
      layers: [mk_basemap],
      view: new View(MapKing["mk_hk"].mk_view),
    });
    return map;*/
    const targetid = this.option.targetid || "map";
    const mk_mapview = new View(MapKing[this.option.bgmap].mk_view);
    const osm = new TileLayer({
      source: new OSM(),
      opacity: 0.8,
    });
    const mk_basemap = new MK_BaseMap(this.option);
    //overlay
    //默认popup控件
    const feature_container = document.getElementById("feature_container");
    const feature_click_container = document.getElementById(
      "feature_click_container"
    );
    const closer = document.getElementById("popup-closer");

    const overlay = new Overlay({
      id: "feature_hover_container",
      element: feature_container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    const overlay_click = new Overlay({
      id: "feature_click_container",
      element: feature_click_container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    closer.addEventListener("click", function () {
      overlay_click.setPosition(undefined);
      closer.blur();
      return false;
    });
    //overlay
    const mk_map = new Map({
      target: targetid,
      layers: [],
      overlays: [overlay, overlay_click],
      view: mk_mapview,
    });
    this.initzoom = Math.round(mk_map.getView().getZoom());
    mk_map.pointermove = true;
    this.map = mk_map;
    const throttledPointermove = throttle((evt) => {
      features_by_hover(evt, mk_map, overlay);
    }, 1000);

    const pointermove = this.option.pointermove;
    const pointerclick = this.option.pointerclick;
    if (pointermove) mk_map.on("pointermove", throttledPointermove);
    if (pointerclick)
      mk_map.on("click", (evt) =>
        features_by_click(evt, mk_map, overlay_click)
      );

    if (this.option.bgmap === "mk_gba" || this.option.bgmap === "mk_sgmy") {
      mk_map.addLayer(osm);
      //mk_map.addLayer(osm_map);
    }
    //这里是MapKing的底图
    mk_map.addLayer(mk_basemap);

    //添加HK_GOV_MAP图层
    if (
      this.option.bgmap === "mk_hk" ||
      this.option.bgmap === "mk_gba" ||
      this.option.bgmap === "mk_res"
    ) {
      const hk_gov_map = new HK_GOV_MAP();
      const hk_gov_basemap = hk_gov_map.get_hk_gov_basemap();
      const hk_gov_imagery = hk_gov_map.get_hk_gov_imagery();
      const hk_gov_maplabel_en = hk_gov_map.get_hk_gov_maplabel_en();
      const hk_gov_maplabel_tc = hk_gov_map.get_hk_gov_maplabel_tc();

      mk_map.addLayer(hk_gov_basemap);
      mk_map.addLayer(hk_gov_imagery);
      mk_map.addLayer(hk_gov_maplabel_en);
      mk_map.addLayer(hk_gov_maplabel_tc);

      //添加矢量图层
      const mk_vectorlayer = new MK_VectorLayer(this.option);

      const hk_mtr_stations = mk_vectorlayer.createVectorLayer(
        hk_mtr_stations_config
      );
      mk_map.addLayer(hk_mtr_stations);

      const hk_mtr_exits =
        mk_vectorlayer.createVectorLayer(hk_mtr_exits_config);
      mk_map.addLayer(hk_mtr_exits);

      const hk_business_listing = mk_vectorlayer.createVectorLayer(
        hk_business_listing_config
      );
      mk_map.addLayer(hk_business_listing);

      const hk_shopping_malls = mk_vectorlayer.createVectorLayer(
        hk_shopping_malls_config
      );
      mk_map.addLayer(hk_shopping_malls);

      if (this.option.bgmap === "mk_gba") {
        const gba_city_poi =
          mk_vectorlayer.createVectorLayer(gba_city_poi_config);
        mk_map.addLayer(gba_city_poi);
      }

      //现在用户自定义的图层添加在这里
      const mk_client_vectorlayer = mk_vectorlayer.createVectorLayer({
        id: "mk_client_vectorlayer",
      });
      const mk_client_vectorSource = new VectorSource({});
      mk_client_vectorlayer.setSource(mk_client_vectorSource);
      mk_map.addLayer(mk_client_vectorlayer);
      this.layers["mk_client_vectorlayer"] = mk_client_vectorlayer;
    }

    //显示坐标
    mk_map.addControl(mousePositionControl);
    mk_map.addControl(zoomControl);
    mk_map.addControl(zoomSlider);

    mk_map.on("rendercomplete", function (e) {
      let zoomLevel = mk_map.getView().getZoom();
      //console.log("zoomLevel", zoomLevel);
      //var zoomRounded = Math.round(zoomLevel * 10) / 10;
      //console.log(zoomRounded);
      //function rad_to_deg(radians) {
      // return radians * (180 / Math.PI);
      //}
      //var map_rotation = mk_map.getView().getRotation();
      //console.log(map_rotation);
      //console.log(rad_to_deg(map_rotation));
    });

    return mk_map;
  }
  setCenter(user_token) {
    //可以先判断地图状态是否在动画中，如果是则不执行下面的代码
    if (
      user_token["zoom"] &&
      user_token["center_lat"] &&
      user_token["center_lon"]
    ) {
      this.map.pointermove = false;

      let zoomlever = Math.round(this.map.getView().getZoom());
      if (zoomlever >= this.initzoom + parseInt(user_token["zoom"])) {
        zoomlever;
      } else {
        zoomlever = this.initzoom + parseInt(user_token["zoom"]);
      }

      this.map.getView().animate(
        {
          zoom: zoomlever,
          duration: 1000,
        },
        {
          center: [user_token["center_lon"], user_token["center_lat"]],
        },
        () => {
          //回调函数，动画完成后执行
          //console.log("动画完成", this.map.pointermove);
          this.map.pointermove = true;
        }
      );
      /*
      this.map
        .getView()
        .setCenter([user_token["center_lon"], user_token["center_lat"]]);

      this.map.getView().animate({
        center: [user_token["center_lon"], user_token["center_lat"]],
      });  */
      //this.map.getView().setZoom(zoomlever + parseInt(user_token["zoom"]));
      addFeatures(this.map, "mk_client_vectorlayer", user_token);
      //要放在动画回调函数中
      //this.map.pointermove = true;
    }
  }
  displayFeatures(user_token) {
    let mk_client_vectorSource = null;
    let mk_client_points_extent = null;
    if (user_token["points"]) {
      const points = user_token["points"];
      for (let i = 0; i < points.length; i++) {
        let point = points[i];
        point["center_lon"] = point["longitude"];
        point["center_lat"] = point["latitude"];
        addFeatures(this.map, "mk_client_vectorlayer", point);
      }
      // 计算包含所有点的范围
      /*const mk_client_vectorSource = getVlayerById(
      this.map,
      "mk_client_vectorlayer"
    ).getSource();*/
      mk_client_vectorSource = this.layers["mk_client_vectorlayer"].getSource();
      mk_client_points_extent = mk_client_vectorSource.getExtent();
      // 调整视图以适应范围
      this.map.getView().fit(mk_client_points_extent, {
        padding: [200, 200, 200, 200],
        duration: 1500, // 动画持续时间（毫秒）
        easing: easeOut, // 缓动函数
      });
    }
    if (user_token["lines"]) {
      const lines = user_token["lines"];
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        addLineFeatures(this.map, "mk_client_vectorlayer", line);
      }
    }
  }
}

export default MK_MAP;
