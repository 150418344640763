/*import MK_MAP from "./src/map";
const mk_map = new MK_MAP({ targetid: "map", bgmap: "mk_gba" });
const map = mk_map.initMap();
map.on("click", (evt) => {
  console.log("web-click", evt.coordinate);
});
*/
import queryString from "query-string";
import { verifyToken, verifyTokenByWeb } from "./src/utils";

import MK_MAP from "./src/map";
import { addFeatures, getVlayerById } from "./src/maputils";

const parsed = queryString.parse(location.search);
const token = parsed.token;

/**
if (token) {
  if (verifyToken(token)) {
    console.log("token is valid");
    //解密成功，显示地图
    //const mk_map = new MK_MAP({ bgmap: "mk_hk" });
    const mk_map = new MK_MAP({ bgmap: "mk_gba" });
    mk_map.initMap();
  } else {
    console.log("token is exist");
  }
} else {
  console.log("token is not exist");
}
*/
//解密成功，显示地图
//const mk_map = new MK_MAP({ bgmap: "mk_hk" });
//layers = layers ? layers : "mk_gba";
let user_token = verifyToken(token);
//let user_token = await verifyTokenByWeb(token);
//alert("user_token:" + user_token);
if (user_token !== false) {
  document.getElementById("page-wrapper").style.display = "none";
  user_token = JSON.parse(user_token);
  //console.log(user_token["uid"]);
  const baselayers = user_token["baselayers"]
    ? user_token["baselayers"]
    : "mk_hk";
  let map_option = { bgmap: baselayers };
  //动态支持修改geoserver的url
  if (user_token["gs_url"]) {
    map_option["gs_url"] = user_token["gs_url"];
  }
  const mk_map = new MK_MAP(map_option);
  let map = mk_map.initMap();
  if (
    user_token["center_lat"] &&
    user_token["center_lon"] &&
    user_token["zoom"]
  ) {
    /*let zoomlever = Math.round(map.getView().getZoom());
    map
      .getView()
      .animate(
        { zoom: zoomlever + parseInt(user_token["zoom"]), duration: 1000 },
        { center: [user_token["center_lon"], user_token["center_lat"]] }
      );
    addFeatures(map, "mk_client_vectorlayer", user_token);*/
    mk_map.setCenter(user_token);
  }
  if (user_token["apitype"] && user_token["apitype"] == "MAP") {
    if (user_token["apimethod"] && user_token["apimethod"] == "display") {
      mk_map.displayFeatures(user_token);
      /*const points = user_token["points"];
      for (let i = 0; i < points.length; i++) {
        let point = points[i];
        point["center_lon"] = point["longitude"];
        point["center_lat"] = point["latitude"];
        addFeatures(map, "mk_client_vectorlayer", point);
      }
      // 计算包含所有点的范围
      const mk_client_vectorSource = getVlayerById(
        map,
        "mk_client_vectorlayer"
      ).getSource();
      const mk_client_points_extent = mk_client_vectorSource.getExtent();
      // 调整视图以适应范围
      map.getView().fit(mk_client_points_extent, {
        padding: [200, 200, 200, 200],
        duration: 1500, // 动画持续时间（毫秒）
        easing: easeOut, // 缓动函数
      });
      */
    }
  }
  //console.log("zoom", map.getView().getZoom(), zoomlever);
} else {
  //document.getElementById("page-wrapper").style.display = "block";
  document.getElementById("map").style.display = "none";
}
//

//=> {foo: 'bar'}
//获取url上的参数key，验证key是否正确
