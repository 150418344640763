import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { Style, Circle, Fill, Icon, Stroke } from "ol/style";

import { mergeOptions } from "./utils";
import { getAssetsUrl } from "./mkenv";
function isCluster(feature) {
  if (!feature || !feature.get("features")) {
    return false;
  }
  return feature.get("features").length > 1;
}
function ifCluster(onFeature) {
  let features = onFeature.get("features");
  let feat_id = "";

  if (isCluster(onFeature)) {
    // is a cluster, so loop through all the underlying features
    //var features = onFeature.get('features');
    //console.log(features.length);
    //console.log("isCluster");
    for (var i = 0; i < features.length; i++) {
      // here you'll have access to your normal attributes:
      //console.log(features[i].get('name_c'));
    }
    onFeature = features[0];
    feat_id = features[0].getId().split(".")[0];
  } else {
    // not a cluster
    //console.log("isNotCluster");
    if (onFeature.getId() == undefined) {
      // isClusterSource
      //console.log("isClusterSource");
      //console.log(features[0].getId().split(".")[0]);
      //console.log(features[0].get('name_c'));
      onFeature = features[0];
      feat_id = features[0].getId().split(".")[0];
    } else {
      // isNotClusterSource
      //console.log("isNotClusterSource");
      //console.log(onFeature.getId());
      //console.log(onFeature.getId().split(".")[0]);
      feat_id = onFeature.getId().split(".")[0];
    }
  }

  return onFeature;
}
function getVlayerById(map, lid) {
  const foundLayer = map
    .getLayers()
    .getArray()
    .find((layer) => layer.get("id") === lid);
  if (foundLayer) return foundLayer;
  else return null;
}
function addFeatures(map, lid, user_token) {
  const vl = getVlayerById(map, lid);
  if (vl) {
    const moption = {
      geometry: new Point([user_token["center_lon"], user_token["center_lat"]]),
      mk_client: "true",
    };
    const toption = mergeOptions(moption, user_token);
    const marker = new Feature(toption);
    const markerStyle = new Style({
      image: new Icon({
        //color: "#BADA55",
        crossorigin: "anonymous",
        src: getAssetsUrl() + "/images/icon/marker.png",
      }),
    });
    marker.setStyle(markerStyle);
    const vectorSource = vl.getSource();
    //vectorSource.clear();
    vectorSource.addFeature(marker);
    //const vectorSource = new VectorSource({
    //  features: [marker],
    //});
    //vl.setSource(vectorSource);
  }
}
function addLineFeatures(map, lid, line) {
  const vl = getVlayerById(map, lid);
  if (vl) {
    const startCoord = [line["start"]["longitude"], line["start"]["latitude"]];
    const endCoord = [line["end"]["longitude"], line["end"]["latitude"]];
    const lineString = new LineString([startCoord, endCoord]); // 指定坐标系
    const moption = {
      geometry: lineString,
      name: line["label"],
      type: line["type"],
      mk_client: "true",
    };
    const toption = mergeOptions(moption, line);
    // 4. 创建 Feature (要素)
    //console.log(toption);
    const feature = new Feature(toption);

    // 5. 定义样式 (可选)
    const lineStyle = new Style({
      stroke: new Stroke({
        color: "blue",
        width: 3,
      }),
    });
    feature.setStyle(lineStyle);
    const vectorSource = vl.getSource();
    vectorSource.addFeature(feature);
  }
}
export { ifCluster, addFeatures, addLineFeatures, getVlayerById };
