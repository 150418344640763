import { ifCluster } from "../utils";

let feature_clicked = null;
function features_by_click(evt, map, overlay) {
  //如果地图正在拖拽或者播放动画效果时，则不处理hover事件。
  //evt.map.getView().getAnimating();检测地图是否在缩放或者旋转或者播放动画效果中【例如flyto】。
  if (
    evt.dragging ||
    !map.pointermove ||
    evt.map.getView().getAnimating() ||
    evt.map.getView().getInteracting()
  ) {
    return;
  }

  if (feature_clicked !== null) {
    //selected.setStyle(undefined);
    feature_clicked = null;
  }

  map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    feature_clicked = feature;
    //selectStyle.getFill().setColor(f.get("COLOR") || "#eeeeee");
    //feature.setStyle(selectStyle);
    return true;
  });

  if (feature_clicked) {
    let getfeatureKeys = [];
    let featureinfo_keys = [];
    let featureinfo = [];
    let display_featureinfo = "";
    let feature_click_container = document.getElementById(
      "feature_click_container"
    );

    //获取feature的属性表{geometry: Wn, extmsg: 'H', iteminfo: {…}}
    const feature_obj = feature_clicked.getProperties();
    //console.log(feature_obj);
    //查找显示的marker 不处理，直接返回
    if (feature_obj["extmsg"]) return;
    //路径规划 不处理，直接返回
    if (feature_obj["road_type"]) return;
    //用户自定义的marker 不处理，直接返回，或者特殊处理
    if (feature_obj["mk_client"]) return;

    let feat_id = null;
    //聚合点
    feature_clicked = ifCluster(feature_clicked);
    if (feature_clicked.getId())
      feat_id = feature_clicked.getId().split(".")[0];

    //地铁出口信息，不处理，直接返回
    if (feat_id == "hk_mtr_exits") {
      //hover_container.style.display = "none";
      return;
    }

    //处理feature的属性表
    for (var i = 0; i < feature_clicked.getKeys().length; i++) {
      var getfeatureProperties = feature_clicked.getKeys();

      getfeatureKeys.push(getfeatureProperties[i]);
      //addClickedMarker(feature_onClick.getGeometry().getCoordinates());

      if (
        feature_clicked.get(getfeatureProperties[i]) == null ||
        getfeatureProperties[i] == "geom" ||
        getfeatureProperties[i] == "id"
      ) {
        // console.log(feature_clicked.get(getfeatureProperties[i]), " is null");
      } else {
        featureinfo_keys.push(getfeatureProperties[i]);
        featureinfo.push(feature_clicked.get(getfeatureProperties[i]));

        if (getfeatureProperties[i].includes("name") == true) {
          display_featureinfo +=
            "<a style='font-size: 15px; font-weight: bold;'>" +
            feature_clicked.get(getfeatureProperties[i]) +
            "</a><br>";
        } else if (getfeatureProperties[i] == "website") {
          display_featureinfo +=
            "<a href='" +
            feature_clicked.get(getfeatureProperties[i]) +
            "' target='_blank' style='color:#0000ff;'>官方網站</a><br>";
        } else {
          display_featureinfo +=
            feature_clicked.get(getfeatureProperties[i]) + "<br>";
        }
      }
    }

    let feature_click_content = document.getElementById(
      "feature_click_content"
    );

    const feature_clicked_view = feature_clicked.getGeometry();
    //console.log(map.getSize());
    //console.log(document.getElementById("featinfo_container").offsetWidth);

    /*map.getView().fit(feature_clicked_view, {
      padding: [0, 0, 0, 350],
      maxZoom: 40,
      duration: 1000,
    });
    */
    const feature_hover_overlay = map.getOverlayById("feature_hover_container");
    if (feature_hover_overlay) feature_hover_overlay.setPosition(undefined);
    map.pointermove = false;
    map.getView().animate(
      {
        center: feature_clicked_view.getCoordinates(),
        duration: 1000,
      },
      () => {
        //回调函数，动画完成后执行
        //console.log("click 动画完成", map.pointermove);
        map.pointermove = true;
      }
    );
    feature_click_content.innerHTML = display_featureinfo;
    feature_click_container.style.display = "block";
    //overlay.setPosition(evt.coordinate);
    overlay.setPosition(feature_clicked_view.getCoordinates());
  } else {
    overlay.setPosition(undefined);
    feature_click_container.style.display = "none";
  }
}

export { features_by_click };
